import React from 'react';
import './css/App.css';
import { useState } from 'react';

import { Zaks_t, F_org, F_kod } from './Zaks_t.js';
import { Comps_t, F_comp } from './Comps_t.js';
import { Posts_t, F_post } from './Posts_t.js';
import { Grts_t, F_grt } from './Grts_t.js';



import Menu_bft from './Menu_bft.js';
import { Context } from './Context.js';




function App(props) {
  window.parm1="parm1"
  const [sizd, Setsizd] = useState({})
  const [slist, Setslist] = useState("")

  let r_list = ""
  let r_form = ""
  if (slist == 'zaks') {
    r_list = <Zaks_t porg={props.porg} Setsizd={Setsizd} />
    if (sizd.kod)
      r_form = <F_kod />   //Заказ 
    else
      if (sizd.org)
        r_form = <F_org />
  }
  else
    if (slist == 'comps'){
      r_list = <Comps_t Setsizd={Setsizd} />
      if (sizd.comp)
        r_form = <F_comp />
    }
    else
      if (slist == 'posts'){
        r_list = <Posts_t Setsizd={Setsizd} />
        if (sizd.post)
          r_form = <F_post />
      }
      else
        if (slist == 'grts') {
          r_list = <Grts_t Setsizd={Setsizd} />
          if (sizd.grt)
            r_form = <F_grt />

        }  
 
  return (
    <div>
      <Menu_bft Setslist={Setslist}>
      </Menu_bft>
      <Context.Provider value={sizd}>
      <div style={{ "display": "flex" }}>
        <div className="r_list" id="r_list" >
          {r_list}
        </div>
        <div className="r_form" id="r_form" >
            {r_form}
        </div>
      </div>
      </Context.Provider >
    </div >
  )

}


export default App;
