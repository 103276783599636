import React from 'react'
import { useState } from 'react';
import nstrs from './constants/data/nstrs.json';
import dks from './constants/data/dks.json';
import './css/r_list.css';
import './css/r_form.css';
import { org_name, tov_name } from './name';
import { Context } from './Context.js';
let posts = []
init_posts()

export function Posts_t(props) {
     
    const [filter, Setfilter] = useState("")
    const cur = React.useContext(Context);
    let value


    let w_ar = []
    posts.forEach((str) => {
        if (str.nak)
            if (cur.post > 0)
              if (str.post !== cur.post)
                return
              else;
            else
              if (str.post !==posts[0].post)
                return
        

        if (filter) {
            if ("norg" in str)
                if (str.norg.indexOf(filter) > -1)
                    w_ar.push(str)
        }
        else
            w_ar.push(str)

    })

    
    let srows = w_ar.map((ar, index) => {

        if (ar.ost == "0")
            ar.ost = " "
        if (ar.nak) {
            
            return (
                <tr id={"post_nak" + ar.post + '_' + ar.nak} className="tr_dop" onClick={() => props.Setsizd(ar)} >
                    <td className="td_zak0" width="150px"> &emsp;Накладная {ar.nak} &emsp; {ar.nom}  </td>
                    <td >{ar.ost}  </td>
                    <td width="25px">{ar.date}  </td>
                   
                </tr >
            )
        }
        else {

            return (
                <tr id={"post" + ar.post} className="tr_main" onClick={() => props.Setsizd(ar)} >
                    <td colSpan="1" width="150px">                     
                        {ar.norg}
                    </td>
                    <td >{ar.ost}  </td>
                    <td width="25px">{ar.date}  </td>
                </tr>
            )
        }
    })



    return (
        <table  >
            <thead>
                <tr>
                    <th colSpan="5">
                        <input value={value} onChange={e => { Setfilter(e.target.value) }} />
                    </th>
                </tr>
                <tr >
                    <th >Поставщик </th>
                    <th  > Остаток  </th>
                    <th > Дата  </th>
                  
                </tr>
            </thead>
            <tbody >
                {srows}
            </tbody>

        </table>

    )
     
     
}

function init_posts() {

    let w_ar = []
    let x = 0
    let p = 0
    nstrs.forEach((nstr) => {
        if ("org" in nstr) {
            if ("ost" in nstr)
                x = 0
            else
                nstr.ost = "0"
            if ("nak" in nstr) x = 0; else nstr.nak = 0;

            p = w_ar.indexOf(nstr.org)
            if (p == -1) {
                w_ar.push(nstr.org)

                posts.push({
                    "post": nstr.org
                    , "norg": org_name(nstr.org)
                    , "ost": nstr.ost
                    , "nom": ""
                    , "nak": ""
                    , "naks": "1"
                })
                //alert("add post " + nstr.org)
            }
            else {
                //alert(Object.keys(posts[p]))
                //alert(posts[p].norg + " " + nstr.org + " p=" + p )
                posts[p].ost = Number(posts[p].ost) + Number(nstr.ost)
            }
            //  формирование dk
            // if (nstr.nak == 0) return;
            p = w_ar.indexOf(nstr.org + "_" + nstr.nak)
            if (p == -1) {
                w_ar.push(nstr.org + "_" + nstr.nak)
                // alert("add post " + nstr.org+"_"+nstr.nak)
                let dk_nom = ""
                for (let i = 0; i < dks.length; i++) {
                    if (dks[i].kod == nstr.nak && dks[i].tdk == 1) {
                        dk_nom = dks[i].nom
                        break
                    }
                }
                posts.push({
                    "post": nstr.org
                    , "norg": org_name(nstr.org)
                    , "ost": nstr.ost
                    , "nak": nstr.nak
                    , "nom": dk_nom
                    , "date": nstr.date
                })
            }
            else {
                // alert(nstr.org+"_"+nstr.nak + " p=" + p )
                posts[p].ost = Number(posts[p].ost) + Number(nstr.ost)
            }
        }
    })

    posts.sort(function (a, b) {
        if (a.norg > b.norg)
            return 1
        else
            if (a.norg == b.norg)
                if (a.nak > b.nak)
                    return 1
                else
                    return -1
            else
                return -1
    })


}
export function F_post(props) {
    const cur = React.useContext(Context);
    const [nsort, Setnsort] = useState(1)
    let x = 0
    let z_nstrs = []; z_nstrs.length = 0;
    nstrs.forEach((nstr) => {
        if (cur.nak && nstr.nak !== cur.nak)
            return
        if (nstr.org == cur.post) {
            if ("ost" in nstr)
                x = 0
            else
                nstr.ost = "0"
            // if (nstr.ost == "0")
            //      nstr.ost = " "
            for (let i = 0; i < dks.length; i++) {
                if (dks[i].tdk == 1 && dks[i].kod == nstr.nak) {

                    z_nstrs.push({
                        "kolt": nstr.kolt
                        , "tov": nstr.tov
                        , "ntov": tov_name(nstr.tov, '')
                        , "post": nstr.org
                        , "nak": nstr.nak
                        , "date": nstr.date
                        , "edate": nstr.edate
                        , "ost": nstr.ost
                    })
                    break
                }
            }
        }
    })


    if (nsort)
        z_nstrs.sort(function (a, b) {
            if (nsort == 1)
                if (a.nak > b.nak)
                    return 1
                else
                    return -1
            else
                if (nsort == 2)
                    if (a.date > b.date)
                        return 1
                    else
                        return -1
                else
                    if (nsort == 3)
                        if (a.ntov > b.ntov)
                            return 1
                        else
                            return -1


        });


    let srows = z_nstrs.map((ar) => {
        return (
            <tr id={"nstr" + ar.kod} className="tr_nstr" onClick={() => props.Setsizd(ar)} >
                <td >{ar.nak}</td>
                <td >{ar.date}</td>
                <td width="250px">{ar.ntov}</td>
                <td>{ar.kolt}</td>
                <td>{ar.ost}</td>
                <td>{ar.edate}</td>
            </tr>
        )
    })

    return (
        <table >
            <thead>
                <tr>
                    <th colSpan="6">
                        <p>  {org_name(cur.post, ' ')}</p>
                    </th>
                </tr>

                <tr>
                    <th onClick={() => Setnsort(1)}>  Рег №</th>
                    <th onClick={() => Setnsort(2)}>  Дата</th>
                    <th onClick={() => Setnsort(3)}>Товар</th>
                    <th >Кол-во</th>
                    <th > Остаток</th>
                    <th className="td_date">Списано</th>
                </tr>
            </thead>
            <tbody>
                {srows}
            </tbody>
        </table>


    )
}
