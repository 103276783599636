import './css/Menu_bft.css';
import mans from './constants/data/mans.json';
let manx = 0
function Menu_bft(props) {
    return (
        <div className="menu">
            <div id="log">
            Login   <input id="login" type="text" />
            Pass    <input id="pass" type="text" />
            </div>
            <nav className="menu_items">
                <a id="zaks" className="menu_item " onClick={(e) => menu_click(e)} href="#">Заказчики</a>
                <a id="comps" className="menu_item" onClick={(e) => menu_click(e)} href="#">Комплектация</a>
                <a id="posts" className="menu_item" onClick={(e) => menu_click(e)} href="#">Поставщики</a>
                <a id="grts" className="menu_item" onClick={(e) => menu_click(e)} href="#">Товары</a>
            </nav>

        </div>
    )
    function menu_click(e) {
        if (manx == 0) {
            let log = document.getElementById("log")
            let login = document.getElementById("login")
            let pass = document.getElementById("pass")
            manx=3
            log.hidden=1 //remove()
            for (let i = 0; i < mans.length; i++)
                if (login.value == mans[i].login && pass.value == mans[i].pass) {
                    manx = mans[i].kod
                    login.remove()
                    pass.remove()
                    break

                }
        }

        if (manx) {
            let el0 = document.getElementsByClassName('menu_active')[0]
            if (el0)
                el0.classList.remove('menu_active')   //menu_active

            let el = document.getElementById(e.target.id);
            el.classList.add('menu_active');
            props.Setslist(e.target.id)
        }
    }
}
export default Menu_bft;