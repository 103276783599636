import React from 'react'
import { useState } from 'react';
import nstrs from './constants/data/nstrs.json';

import kgrt from './constants/data/kgrt.json';
import tovs from './constants/data/tovs.json';
import './css/r_list.css';
import './css/r_form.css';
import { org_name, tov_name, grt_name } from './name.js';
import { Context } from './Context.js';

let grts = []
init_grts()

export function Grts_t(props) {
    const cur = React.useContext(Context);
    const [filter, Setfilter] = useState("")

    let value

    let fp = -1
    let w_ar = []
    grts.forEach((grt, index) => {
        if (fp == -1 && "loaded" in grt && grt.loaded)
            fp = index
        // alert(fp)
        if (grt.org)
            if (cur.grt > 0)
                if (grt.grt !== cur.grt)
                    return
                else;
            else
                if (grt.grt !== grts[fp].grt)
                    return
        if (filter) {
            if ("ntov" in grt)
                if (grt.ntov.indexOf(filter) > -1)
                    w_ar.push(grt)
        }
        else
            w_ar.push(grt)

    })

    let srows = w_ar.map((ar, index) => {
        let w_name = ""
        if (ar.lev == 3)
            w_name = "."
        else
            if (ar.lev == 4)
                w_name = ".."
            else
                if (ar.lev == 5)
                    w_name = "..."
        if (ar.org) {
            return (
                <tr id={"grt" + ar.grt + '_' + ar.org} className={"tr_dop"} onClick={(e) => props.Setsizd(ar)} >
                    <td colSpan="1" width="350px">&emsp;{ar.norg}  </td>
                    <td >{ar.kolt}  </td>
                    <td >{ar.ost}  </td>
                </tr >
            )
        }
        else {

            return (
                <tr id={"grt" + ar.grt} className="tr_main" onClick={(e) => props.Setsizd(ar)} >
                    <td colSpan="1" width="350px">
                        {w_name}  {ar.name}
                    </td>
                    <td >{ar.kolt}  </td>
                    <td >{ar.ost}  </td>
                </tr>
            )

        }
    })



    return (
        <table id="grts_t" >
            <thead>
                <tr>
                    <th colSpan="5">
                        <input value={value} onChange={e => { Setfilter(e.target.value) }} />
                    </th>
                </tr>
                <tr >
                    <th  > Группа / Поставщик </th>
                    <th   > Кол-во  </th>
                    <th   > Остаток  </th>
                </tr>
            </thead>
            <tbody >
                {srows}
            </tbody>
        </table>
    )



}

function init_grts() {

    let w_ar = []
    let w_lev = 0

    kgrt.forEach((grt) => {
        let p = w_ar.indexOf(grt.kod)
        if (p == -1) {
            w_ar.push(grt.kod)
            grts.push({                 // группа
                "grt": grt.kod
                , "name": grt.name
                , "lev": grt.lev
                , "ost": ""
                , "kolt": ""
                , "org": ""
                , "norg": ""
                , "loaded": 0
            })
        }
        p = grts.length - 1



        tovs.forEach((tov) => {
            if (tov.grt !== grt.kod) return

            nstrs.forEach((nstr) => {
                if (nstr.tov !== tov.kod) return
                if ("org" in nstr && nstr.org > 0) {
                    let pt = w_ar.indexOf(grt.kod + '_' + nstr.org)
                    if (pt == -1) {
                        w_ar.push(grt.kod + '_' + nstr.org)
                        grts[p].loaded++
                        grts.push({
                            "grt": grt.kod
                            , "org": nstr.org
                            , "norg": org_name(nstr.org)
                            , "ost": nstr.ost
                            , "kolt": nstr.kolt
                            , "loaded": ""
                        })
                    }
                    else {
                        grts[pt].ost = Number(grts[pt].ost) + Number(nstr.ost)
                        grts[pt].kolt = Number(grts[pt].kolt) + Number(nstr.kolt)
                    }
                    grts[p].ost = Number(grts[p].ost) + Number(nstr.ost)
                    grts[p].kolt = Number(grts[p].kolt) + Number(nstr.kolt)
                }
            })
        })
    })
    // tovs.sort(function (a, b) {
    //     if (nsort == 1)
    //         if (a.norg > b.norg)
    //             return 1
    //         else
    //             return -1
    // })



}
export function F_grt(props) {
    const cur = React.useContext(Context);
    const [nsort, Setnsort] = useState(1)
    let x = 0
    let z_nstrs = []; z_nstrs.length = 0;
    let shead = ""; let shead1 = "";
    if ("org" in cur && cur.org) {
        shead = org_name(cur.org)
        shead1 = grt_name(cur.grt)
        tovs.forEach((tov) => {
            if (tov.grt !== cur.grt) return
            nstrs.forEach((nstr) => {
                if (nstr.org !== cur.org || nstr.tov !== tov.kod)
                    return
                z_nstrs.push({
                    "kolt": nstr.kolt
                    , "tov": nstr.tov
                    , "ntov": tov_name(nstr.tov, '')
                    , "post": nstr.org
                    , "norg": org_name(nstr.org)
                    , "nak": nstr.nak
                    , "date": nstr.date
                    , "edate": nstr.edate
                    , "ost": nstr.ost
                })
            })
        })
    }
    else {
        if (cur.grt) {
            shead = grt_name(cur.grt)
            tovs.forEach((tov) => {
                if (tov.grt !== cur.grt)
                    return
                nstrs.forEach((nstr) => {
                    if (nstr.tov !== tov.kod)
                        return
                    z_nstrs.push({
                        "kolt": nstr.kolt
                        , "tov": nstr.tov
                        , "ntov": tov_name(nstr.tov, '')
                        , "post": nstr.org
                        , "norg": org_name(nstr.org)
                        , "nak": nstr.nak
                        , "date": nstr.date
                        , "edate": nstr.edate
                        , "ost": nstr.ost
                    })
                })
            })
        }
    }

    z_nstrs.sort(function (a, b) {
        if (nsort == 1)
            if (a.date > b.date)
                return 1
            else
                return -1
        else
            if (nsort == 2)
                if (a.norg > b.norg)
                    return 1
                else
                    return -1
            else
                if (nsort == 3)
                    if (a.ntov > b.ntov)
                        return 1
                    else
                        return -1
    });


    let srows = z_nstrs.map((ar) => {
        return (
            <tr id={"nstr" + ar.kod} className="tr_nstr"  >

                <td >{ar.date}</td>
                <td width="250px">{ar.norg}</td>
                <td width="250px">{ar.ntov}</td>
                <td>{ar.kolt}</td>
                <td>{ar.ost}</td>
                <td>{ar.edate}</td>
            </tr>
        )
    })

    return (
        <table >
            <thead>
                <tr>
                    <th colSpan="6">
                        <p>  {shead}</p>
                        <p>  {shead1}</p>
                    </th>
                </tr>

                <tr>
                    <th onClick={() => Setnsort(1)}>  Дата</th>
                    <th onClick={() => Setnsort(2)}>Поставщик</th>

                    <th onClick={() => Setnsort(3)}>Товар</th>
                    <th >Кол-во</th>
                    <th > Остаток</th>
                    <th className="td_date">Списано</th>
                </tr>
            </thead>
            <tbody>
                {srows}
            </tbody>
        </table>


    )
}

