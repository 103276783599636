import React from 'react'
import { useState } from 'react';
import izds from './constants/data/izds.json';
import tstrs from './constants/data/tstrs.json'
import './css/r_list.css';
import './css/r_form.css';
import { org_name, tov_name } from './name';
import { Context } from './Context.js';

let zorgs = []; init_zorgs()// Заказчики

export function Zaks_t(props) {
  //alert(window.parm1)
  const cur = React.useContext(Context);
  const [filter, Setfilter] = useState("")
   
  let value
 
  let w_izds = []; w_izds.length = 0

  zorgs.forEach((zorg) => { //клиенты 
    if (zorg.kod)
      if (cur.org > 0)
        if (zorg.org !== cur.org)
          return
        else;
      else
        if (zorg.org !==zorgs[0].org)
          return
    if (props.porg > 0 && zorg.org !== props.porg)  
      return
    
    if (filter) {
      if ("norg" in zorg)
        if (zorg.norg.indexOf(filter) == -1)
          return
    }
    w_izds.push(zorg)
  })

   

  let srows = w_izds.map((ar) => {
    if (ar.kod) {
      

      return (
        <tr id={"zak" + ar.kod} className="tr_dop" onClick={() => props.Setsizd(ar)}>
          <td className="td_right" width="250px">Заказ {ar.kod}  </td>
       
          <td width="150px">{ar.ntov}  </td>
          <td>{ar.kolt}</td>
          <td >{ar.sdate}</td>
        </tr >
      )
    }
    else {

      return (
        <tr id={"org" + ar.org} className="tr_main" onClick={() => props.Setsizd(ar)}>
          <td colSpan="1" width="250px">
            
            {ar.norg}
          </td>
          <td width="150px">{ar.ntov}  </td>
          <td>{ar.kolt}</td>
          <td >{ar.sdate}</td>
        </tr>
      )
    }

  })



  return (
    <table  >
      <thead>
        <tr>
          <th colSpan="5">
            <input value={value} onChange={e => { Setfilter(e.target.value) }} />
          </th>
        </tr>
        <tr >
          <th >Клиент </th>
         
          <th   >Изделие </th>
          <th  >Кол-во  </th>
          <th  >Дата  </th>
        </tr>
      </thead>
      <tbody >
        {srows}
      </tbody>

    </table>

  )
  

}
function init_zorgs() {
  let w_ar = []; w_ar.length = 0;
  let p = 0
  izds.forEach((izd) => {
    izd.norg = org_name(izd.org)
    if (izd.norg == "" || izd.kod == 0)
      return
    izd.ntov = tov_name(izd.tov, 'first')
    p = w_ar.indexOf(izd.org)
    if (p == -1) {
      w_ar.push(izd.org)
      zorgs.push({
        "kolt": izd.kolt
        , "zaks": 1
        , "kod": ""
        , "tov": izd.tov
        , "ntov": izd.ntov
        , "org": izd.org
        , "norg": izd.norg
        , "sdate": ""
      })
    }
    else {
      zorgs[p].zaks++
      zorgs[p].kolt = Number(zorgs[p].kolt) + Number(izd.kolt)
      if (zorgs[p].ntov && zorgs[p].ntov !== izd.ntov) {
        zorgs[p].ntov = ""
        zorgs[p].tov = ""
      }
    }
    // заказы 
    p = w_ar.indexOf(izd.org + "_" + izd.kod)
    if (p == -1) {
      w_ar.push(izd.org + "_" + izd.kod)
      zorgs.push({
        "kolt": izd.kolt
        , "zaks": ""
        , "kod": izd.kod
        , "org": izd.org
        , "tov": izd.org
        , "ntov": izd.ntov
        , "norg": izd.norg
        , "sdate": izd.sdate
      })
    }
  })
  zorgs.sort(function (a, b) {
    if (a.norg > b.norg)
      return 1
    else
      if (a.norg == b.norg)
        if (a.sdate > b.sdate)
          return 1
        else
          return -1
      else
        return -1;
  })
}

export function F_org(props) {
  const cur = React.useContext(Context);
  
  const [nsort, Setnsort] = useState(1)
  let z_ar = []; z_ar.length = 0
  let t_ar = []; t_ar.length = 0
  let z_tovs = []; z_tovs.length = 0;
  izds.forEach((izd) => {
    if (izd.org > 0 && izd.org == cur.org) {
      if (z_ar.indexOf(izd.kod) == -1)
        z_ar.push(izd.kod)
    }
  })
  let p = 0
  tstrs.forEach((tstr) => {
    if (z_ar.indexOf(tstr.izd) == -1)
      return
    p = t_ar.indexOf(tstr.tov + '_' + tstr.org)
    if (p == -1) {
      t_ar.push(tstr.tov + '_' + tstr.org)
      z_tovs.push({
        "kolt": tstr.kolt
        , "tov": tstr.tov
        , "ntov": tov_name(tstr.tov, 'first')
        , "org": tstr.org
        , "norg": org_name(tstr.org)
        , "sdate": tstr.sdate
      })
    }
    else {
      z_tovs[p].kolt = Number(z_tovs[p].kolt) + Number(tstr.kolt)
    }
  })
  if (nsort) {
    z_tovs.sort(function (a, b) {
      if (nsort == 1)
        if (a.ntov > b.ntov)
          return 1
        else
          return -1
      else
        if (nsort == 2)
          if (a.norg > b.norg)
            return 1
          else
            return -1
    })
  }


  let srows = z_tovs.map((ar) => {
    return (
      <tr id={"tov" + ar.tov} className="tr_tstr">
        <td width="250px">{tov_name(ar.tov, ' ')}</td>
        <td width="150px">{org_name(ar.org, ' ')}</td>
        <td>{ar.kolt}</td>
        <td >{ar.sdate}</td>
      </tr>
    )
  })

  return (


    <table >
      <thead>

        <tr>
          <th colSpan="4">
            <p>  {org_name(cur.org, ' ')}</p>
          </th>
        </tr>

        <tr>
          <th onClick={() => Setnsort(1)}> Комплектация</th>
          <th onClick={() => Setnsort(2)}>Поставщик</th>
          <th className="td_num"> Кол-во</th>
          <th className="td_date">Дата</th>
        </tr>
      </thead>
      <tbody>
        {srows}
      </tbody>
    </table>


  )


}
export function F_kod(props) {
  const cur = React.useContext(Context);
  const [nsort, Setnsort] = useState(1)
   

  let qstr = tstrs.filter((str) => {
    if (str.izd === cur.kod) {
      str.norg = org_name(str.org)
      str.ntov = tov_name(str.tov, 'first')
      if ("post" in cur && cur.post > 0) {
        if (cur.post == str.org)
          return str
      }
      else
        return str
    }
  })
  if (nsort) {

    qstr.sort(function (a, b) {
      if (nsort == 1)
        if (a.sq - b.sq)
          return 1
        else
          return -1
      else
        if (nsort == 2)
          if (a.ntov > b.ntov)
            return 1
          else
            return -1
        else
          if (nsort == 3)
            if (a.norg > b.norg)
              return 1
            else
              return -1

    });
  }
  //console.log(tstr)

  let srows = qstr.map((ar) => {
    return (
      <tr id={"tov" + ar.tov} className="tr_tstr">
        <td>{ar.sq}</td>
        <td width="250px">{tov_name(ar.tov, ' ')}</td>
        <td width="150px">{org_name(ar.org, ' ')}</td>
        <td>{ar.kolt}</td>
        <td >{ar.sdate}</td>
      </tr>
    )
  })

  return (
    <table >
      <thead>
        <tr>
          <th colSpan="5">
            <span >Заказ {cur.kod} Дата {cur.sdate}</span>
          </th>
        </tr>
        <tr>
          <th colSpan="5">
            <p>  {org_name(cur.org, ' ')}</p>
          </th>
        </tr>

        <tr>
          <th onClick={() => Setnsort(1)}> sq</th>
          <th onClick={() => Setnsort(2)}> Комплектация</th>
          <th onClick={() => Setnsort(3)}>Поставщик</th>
          <th className="td_num"> Кол-во</th>
          <th className="td_date">Дата</th>
        </tr>
      </thead>
      <tbody>
        {srows}
      </tbody>
    </table>


  )


}
