import React from 'react'
import { useState } from 'react';
import tstrs from './constants/data/tstrs.json';
import izds from './constants/data/izds.json';
import './css/r_list.css';
import './css/r_form.css';
import { org_name, tov_name } from './name';
import { Context } from './Context.js';

let zcomps = []; zcomps.length = 0
init_zcomps()  // комплектация

export function Comps_t(props) {
  const cur = React.useContext(Context);
   
  const [filter, Setfilter] = useState(" ")
   
  let value

  let w_izds = []; w_izds.length = 0
  zcomps.forEach((zcomp) => {
    if (zcomp.kod)
      if (cur.comp > 0)
        if (zcomp.comp !== cur.comp)
          return
        else;
      else
        if (zcomp.comp !==zcomps[0].comp)
          return


    if (filter) {
      if ("norg" in zcomp)
        if (zcomp.norg.indexOf(filter) == -1)
          return
    }
    w_izds.push(zcomp)
  })

  let srows = w_izds.map((ar) => {
    if (ar.kod) {
     
      return (
        <tr id={"zak" + ar.kod} className="tr_dop" onClick={() => props.Setsizd(ar)}>

          <td width="250px">&emsp;Заказ {ar.kod}&emsp;  {ar.norg}  </td>
          
          <td>{ar.kolt}</td>
          <td >{ar.date}</td>
        </tr >
      )
    }
    else
      return (
        <tr id={"comp" + ar.comp} className="tr_main" onClick={() => props.Setsizd(ar)}>
          <td colSpan="1" width="250px">
           
            {ar.ncomp}
          </td>
          <td>{ar.kolt}</td>
          <td >{ar.date}</td>
        </tr>
      )
  })



  return (
    <table  >
      <thead>
        <tr>
          <th colSpan="5">
            <input value={value} onChange={e => { Setfilter(e.target.value) }} />
          </th>
        </tr>
        <tr >

          <th  >Клиент </th>
         
          <th  >Кол-во  </th>
          <th >Дата  </th>
        </tr>
      </thead>
      <tbody >
        {srows}
      </tbody>

    </table>

  )
  

}
function init_zcomps() {
  let w_ar = []; w_ar.length = 0;
  let p = 0
  let p_main = 0
  let x = 0
  tstrs.forEach((tstr) => {
    if ("org" in tstr)
      x = 0
    else
      return
    p = w_ar.indexOf(tstr.org)
    if (p == -1) {
      w_ar.push(tstr.org)
      zcomps.push({
        "kolt": tstr.kolt
        , "zaks": 1
        , "kod": ""
        , "org": ""
        , "ntov": ""
        , "ncomp": org_name(tstr.org)
        , "comp": tstr.org
        , "date": ""
      })
      p_main = zcomps.length - 1
    }
    else {
      zcomps[p].zaks++
      p_main = p
    }
    // заказы
    p = w_ar.indexOf(tstr.org + '_' + tstr.izd)
    if (p == -1) {
      w_ar.push(tstr.org + '_' + tstr.izd)
      let izd_org = ""
      let izd_date = ""
      let izd_tov = ""
      for (let i = 0; i < izds.length; i++)
        if (izds[i].kod == tstr.izd) {
          izd_org = izds[i].org
          izd_date = izds[i].sdate
          izd_tov = izds[i].tov
          break
        }

      zcomps.push({
        "kolt": tstr.kolt
        , "zaks": ""
        , "kod": tstr.izd
        , "org": izd_org
        , "norg": org_name(izd_org)
        , "ntov": tov_name(izd_tov, 'first')
        , "comp": tstr.org
        , "ncomp": org_name(tstr.org)
        , "date": izd_date
      })
    }
    else {
      zcomps[p].kolt = Number(zcomps[p].kolt) + Number(tstr.kolt)
      zcomps[p_main].kolt = Number(zcomps[p_main].kolt) + Number(tstr.kolt)
    }
    zcomps.sort(function (a, b) {
      if (a.ncomp > b.ncomp)
        return 1
      else
        if (a.ncomp == b.ncomp)
          if (a.kod > b.kod)
            return 1
          else
            return -1;
        else
          return -1;
    })
  })
}
export function F_comp(props) {
  const cur = React.useContext(Context);
  const [nsort, Setnsort] = useState(1)

  let z_tovs = []; z_tovs.length = 0;
  for (let t = 0; t < tstrs.length; t++) {
    if (tstrs[t].org == cur.comp) {
      for (let i = 0; i < izds.length; i++) {
        if (izds[i].kod > 0 && izds[i].kod == tstrs[t].izd) {
          z_tovs.push({
            "kolt": tstrs[t].kolt
            , "tov": tstrs[t].tov
            , "ntov": tov_name(tstrs[t].tov, '')
            , "org": izds[i].org
            , "izd": izds[i].kod
            , "norg": org_name(izds[i].org)
            , "date": tstrs[t].sdate
          })
        }
      }
    }
  }


  if (nsort)
    z_tovs.sort(function (a, b) {
      if (nsort == 1)
        if (a.ntov > b.ntov)
          return 1
        else
          return -1
      else
        if (nsort == 2)
          if ((a.izd - b.izd) > 0)
            return 1
          else
            return -1
        else
          if (nsort == 3)
            if (a.norg > b.norg)
              return 1
            else
              return -1
    });

  //console.log(tstr)

  let srows = z_tovs.map((ar) => {
    return (
      <tr id={"tov" + ar.tov} className="tr_tstr">
        <td width="250px">{ar.ntov}</td>
        <td>{ar.izd}</td>
        <td width="150px">{ar.norg}</td>
        <td>{ar.kolt}</td>
        <td >{ar.date}</td>
      </tr>
    )
  })

  return (
    <table >
      <thead>
        <tr>
          <th colSpan="4">
            <p>  {org_name(cur.comp, ' ')}</p>
          </th>
        </tr>

        <tr>
          <th onClick={() => Setnsort(1)}> Комплектация</th>
          <th onClick={() => Setnsort(2)}>Заказ</th>
          <th onClick={() => Setnsort(3)}>Клиент</th>
          <th className="td_num"> Кол-во</th>
          <th className="td_date">Дата</th>
        </tr>
      </thead>
      <tbody>
        {srows}
      </tbody>
    </table>


  )
}